<template lang="pug">
div.d-flex.justify-end
  div.d-flex.align-items-center(v-for="icon in controlButtons" :key="icon.id")
    v-tooltip(top v-if="icon.hasOwnProperty('checkAccess') ? icon.checkAccess(item) : true" :disabled="!icon.hasOwnProperty('tooltip')")
      template(#activator='{ on, attrs }')
        router-link(
          v-if="icon.hasOwnProperty('to')"
          :title="$t(icon.tooltip)"
          :to="icon.to(item)"
          :target="icon.target || null")
          v-btn(
          v-bind='attrs'
          v-on='on'
          :x-small="icon.buttonOptions ? icon.buttonOptions.xSmall : true"
          :fab="icon.buttonOptions ? icon.buttonOptions.fab : true"
          :icon="icon.buttonOptions ? icon.buttonOptions.icon : true"
          :color="icon.buttonOptions ? icon.buttonOptions.color : ''"
          :outlined="icon.buttonOptions ? icon.buttonOptions.outlined : false").mr-2
            v-icon(:color="icon.color") {{icon.name}}
        v-btn(
          v-else
          v-bind='attrs'
          v-on='on'
          :loading="isLoading === icon.id"
          :color="icon.buttonOptions ? icon.buttonOptions.color : ''"
          :x-small="icon.buttonOptions ? icon.buttonOptions.xSmall : true"
          :fab="icon.buttonOptions ? icon.buttonOptions.fab : true"
          :icon="icon.buttonOptions ? icon.buttonOptions.icon : true"
          :outlined="icon.buttonOptions ? icon.buttonOptions.outlined : false"
          @click="handleAction(icon)").mr-2
          v-icon(:color="icon.color") {{icon.name}}
      span {{ $t(icon.tooltip) }}
</template>

<script>
export default {
  props: {
    controlButtons: { type: Array, default: () => [] },
    item: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isLoading: null
    }
  },
  methods: {
    async handleAction (icon) {
      this.isLoading = icon.id
      this.item ? await icon.action(this.item) : await icon.action()
      this.isLoading = null
    }
  }
}

</script>
